import { FC, useEffect } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import { BadgePlus } from 'lucide-react';

import AddCredit from '@/assets/icons/add-credits.svg?react';
import Credit from '@/assets/icons/credits.svg?react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { useUserContext } from '@/context/user';
import { useCreateAddOnCreditsCheckout } from '@/features/subscription';
import { PlanInterval } from '@/lib/api';

import { Badge } from './ui/badge';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';

interface CreditsCardProps extends React.ComponentProps<typeof Card> {
  disabled?: boolean;
}

export const CreditsCard: FC<CreditsCardProps> = ({ disabled, ...props }) => {
  const { user } = useUserContext();

  const stripe = useStripe();

  const { mutate, data } = useCreateAddOnCreditsCheckout();

  useEffect(() => {
    if (data) {
      try {
        stripe?.redirectToCheckout({
          sessionId: data.session.id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [data, stripe]);

  if (!user) return null;

  const isAllSuppliersUnlocked = user.plan?.unlock_suppliers;
  const creditsRemaining = isAllSuppliersUnlocked
    ? '∞'
    : user?.creditsRemaining;
  const addOnCredits = isAllSuppliersUnlocked ? '∞' : user?.addOnCredits;
  const totalAmount = isAllSuppliersUnlocked
    ? '∞'
    : user?.creditsRemaining + user?.addOnCredits;

  const creditPriceCoefficient = user.plan?.addon_credit_price ?? 3;

  const credits = [10, 25, 75, 100];

  return (
    <Dialog>
      <Card {...props}>
        <CardHeader className="p-4">
          <CardTitle className="flex items-center gap-1.5 text-sm">
            <Credit className="h-5 w-5" />
            Credits
            <span className="ml-auto block rounded-md bg-yellow-500 p-1 px-1.5 text-xs font-semibold leading-3 text-white shadow-[0_1px_0_0_rgba(255,255,255,0.20)_inset,_0_-1px_0_0_rgba(0,0,0,0.10)_inset,_0_0_0_4px_rgba(255,255,255,0.07),_0_0_5px_0_rgba(253,178,7,0.10)]">
              {totalAmount}
            </span>
          </CardTitle>
        </CardHeader>
        <Separator />
        <CardContent className=" p-4 text-muted-foreground">
          <p className="mb-2 flex text-xs font-medium">
            {user.plan?.interval === PlanInterval.MONTH ? 'Monthly' : 'Yearly'}{' '}
            Balance <span className="ml-auto">{creditsRemaining}</span>
          </p>
          <p className="mb-3 flex text-xs font-medium">
            Add-On Credits <span className="ml-auto">{addOnCredits}</span>
          </p>
          <DialogTrigger asChild>
            <Button
              className="w-full"
              size="sm"
              disabled={disabled || isAllSuppliersUnlocked}
            >
              <BadgePlus className="mr-[6px]" size={14} />
              Add Credits
            </Button>
          </DialogTrigger>
        </CardContent>
      </Card>
      <DialogContent className="gap-6 sm:max-w-[500px]">
        <DialogHeader className="items-center space-y-4">
          <AddCredit />
          <DialogTitle className="">Add More Credits</DialogTitle>
          <DialogDescription>
            Choose the number of credits you would like to add.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 sm:grid-cols-2 sm:grid-rows-2">
          {credits.map((credit) => (
            <Badge
              variant="outline"
              className="justify-between rounded-md p-3 text-sm"
              key={credit}
            >
              {credit} Credits
              <Button
                size="sm"
                className="px-4 py-2 text-xs"
                onClick={() => mutate({ quantity: credit })}
              >
                ${credit * creditPriceCoefficient}
              </Button>
            </Badge>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
